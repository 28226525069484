import React from 'react';
import styles from './FullPageLoadingSpinner.module.css';

const FullPageLoadingSpinner = () => {
  return (
    <div className={styles.container}>
      <div className={styles.spinner}></div>
    </div>
  );
};

export default FullPageLoadingSpinner;