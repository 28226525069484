import { useEffect, useState } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';

function useIsLoggedIn() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const session = await fetchAuthSession();
        setIsLoggedIn(!!session?.tokens); // Set to true if tokens exist
      } catch {
        setIsLoggedIn(false); // Set to false if no valid session
      } finally {
        setLoading(false); // Stop loading once the check completes
      }
    };

    checkSession();
  }, []);

  return { isLoggedIn, loading };
}

export default useIsLoggedIn;