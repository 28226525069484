import React from "react";
import { Routes, Route } from "react-router-dom";
// import { Routes, Route, Navigate } from "react-router-dom";

function BlueprintsRoute() {
  return (
    <Routes>
      {/* all blueprints */}
      <Route index element={<h1>this is the view all blueprints page, and is an index route</h1>} />
      {/* view a specific blueprint */}
      {/* 404 not found */}
      <Route path="*" element={<h1>this is the placeholder 404 page for the private routes</h1>} />
    </Routes>

    // TEMP WHILE DEV IN PROGRESS
    // <Routes>
    //   {/* all blueprints */}
    //   <Route index element={<Navigate to="/" replace />} />
    //   {/* view a specific blueprint */}
    //   {/* 404 not found */}
    //   <Route path="*" element={<Navigate to="/404" replace />} />
    // </Routes>
  );
}

export default BlueprintsRoute;
