// React imports
import React, { useState, useEffect } from "react";

// External library imports
import { useSearchParams, useNavigate } from "react-router-dom";
import { confirmSignUp, resendSignUpCode } from "aws-amplify/auth";
import { IconLock } from "@tabler/icons-react";

// MUI component imports
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";

// Local component imports
import AuthTitleText from "../../../components/auth/AuthTitleText/AuthTitleText";
import ActionSection from "../../../components/auth/ActionSection/ActionSection";
import InputGroup from "../../../components/common/InputGroup/InputGroup";
import AuthLink from "../../../components/auth/AuthLink/AuthLink";
import FormAlert from "../../../components/common/FormAlert/FormAlert";

// Utility imports
import { confirmationCodeIsValid } from "../../../util/validation/inputValidation";

function ConfirmAccount() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const username = searchParams.get("username");
  const [accountConfirmed, setAccountConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [resendCodeErrorMessage, setResendCodeErrorMessage] = useState(null);
  const [resendCodeSuccessMessage, setResendCodeSuccessMessage] =
    useState(null);
  const [codeSent, setCodeSent] = useState(false);
  const [formData, setFormData] = useState({
    confirmationCode: "",
  });
  const [formErrors, setFormErrors] = useState({
    confirmationCode: "",
  });

  // If username param not found re-direct to login
  useEffect(() => {
    if (!username) {
      console.log(
        "No username url parameter found. Navigating to the sign in page."
      );
      navigate("/auth/signin");
    }
  }, [username, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const validateField = (name, value) => {
    switch (name) {
      case "confirmationCode":
        return !confirmationCodeIsValid(value)
          ? "Please enter the 6-digit verification code we sent you"
          : "";
      default:
        return "";
    }
  };

  const handleUserFinished = () => {
    navigate("/auth/signin");
  };

  // Handle subscribe button click
  const handleSubmit = async () => {
    const confirmationCodeError = validateField(
      "confirmationCode",
      formData.confirmationCode
    );

    setFormErrors({
      confirmationCode: confirmationCodeError,
    });

    if (confirmationCodeError) {
      return;
    }

    setLoading(true);
    setSubmitError(null);

    try {
      await confirmSignUp({
        username: username,
        confirmationCode: formData.confirmationCode,
      });

      // On success show redirect prompt
      setAccountConfirmed(true);
    } catch (error) {
      let errorMessage;

      // Catch cognito service errors
      if (error.name) {
        switch (error.name) {
          case "CodeMismatchException":
            errorMessage =
              "The verification code you entered is incorrect. Please try again.";
            break;
          case "ExpiredCodeException":
            errorMessage =
              "The verification code has expired. Please request a new one.";
            break;
          case "LimitExceededException":
            errorMessage =
              "You've exceeded the maximum number of allowed requests. Please try again later.";
            break;
          case "TooManyFailedAttemptsException":
            errorMessage =
              "You've made too many failed attempts to confirm your account. Please wait a moment and try again.";
            break;
          default:
            errorMessage = "An unexpected error occurred.";
        }
      } else {
        errorMessage = "An unexpected error occurred.";
      }

      console.error("Error during account account confirmation:", error);
      setSubmitError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleResendCode = async () => {
    // Prevent resending if a code was already sent
    if (codeSent) {
      return;
    }

    setResendCodeErrorMessage(null);
    setResendCodeSuccessMessage(null);

    try {
      // Attempt to resend the code
      // do here ....
      await resendSignUpCode({ username });
      setCodeSent(true); // Success

      // Success message
      setResendCodeSuccessMessage(
        "A new verification code has been sent to your email!"
      );

      // Countdown timer for 15 seconds before allowing a new code resend
      let count = 15;
      const intervalId = setInterval(() => {
        if (count === 0) {
          clearInterval(intervalId);
          setResendCodeSuccessMessage(null);
          setCodeSent(false);
          return;
        }
        count--;
      }, 1000);
    } catch (error) {
      // Handle error cases for resending the code
      let errorMessage;

      // Catch cognito service errors
      if (error.name) {
        switch (error.name) {
          case "CodeDeliveryFailureException":
            errorMessage =
              "We were unable to deliver the verification code. Please try again later.";
            break;
          case "LimitExceededException":
            errorMessage =
              "You've exceeded the maximum number of allowed requests. Please try again later.";
            break;
          case "TooManyRequestsException":
            errorMessage =
              "You've made too many requests. Please wait a moment and try again.";
            break;
          default:
            errorMessage = "An unexpected error occurred.";
        }
      } else {
        errorMessage = "An unexpected error occurred.";
      }

      console.error("Error resending account confirmation code:", error);
      setResendCodeErrorMessage(errorMessage);
      setCodeSent(false); // Allow the user to try again after an error
    }
  };

  const confirmAccountContent = (
    <div>
      <AuthTitleText
        title="Confirm Your Account"
        text="We sent a verification code to your email address. Please enter the verification code below to confirm your WealthSphere account."
      />
      <ActionSection>
        <InputGroup>
          <TextField
            id="confirmationCode"
            name="confirmationCode"
            label="Verification Code"
            placeholder="123456"
            type="text"
            variant="outlined"
            required
            fullWidth
            value={formData.confirmationCode}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            error={!!formErrors.confirmationCode}
            helperText={formErrors.confirmationCode}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <IconLock size={24} />
                  </InputAdornment>
                ),
              },
            }}
          />
        </InputGroup>
        {submitError && <FormAlert type="error" message={submitError} />}
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit}
          fullWidth
          startIcon={
            loading ? (
              <CircularProgress
                thickness={4}
                size={16}
                sx={{ color: "#FFFFFF" }}
              />
            ) : null
          }
        >
          Confirm
        </Button>
        {resendCodeErrorMessage && (
          <FormAlert type="error" message={resendCodeErrorMessage} />
        )}
        {resendCodeSuccessMessage && (
          <FormAlert type="success" message={resendCodeSuccessMessage} />
        )}
        <p style={{ textAlign: "center" }} className="normal-text-regular">
          Didn't get a code?{" "}
          <span>
            <AuthLink onClick={handleResendCode} to="#" text="Resend Code" />
          </span>
        </p>
      </ActionSection>
    </div>
  );

  const accountConfirmedContent = (
    <div>
      <AuthTitleText
        title="Account Confirmed"
        text="Your WealthSphere account has been successfully confirmed. You can now log in and access your account."
      />
      <ActionSection>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleUserFinished}
          fullWidth
        >
          Login
        </Button>
      </ActionSection>
    </div>
  );

  return accountConfirmed ? accountConfirmedContent : confirmAccountContent;
}

export default ConfirmAccount;
