import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useIsLoggedIn from '../../hooks/useIsLoggedIn/useIsLoggedIn';
import FullPageLoadingSpinner from '../../components/common/FullPageLoadingSpinner/FullPageLoadingSpinner';

function PrivateRoute() {
  const { isLoggedIn, loading } = useIsLoggedIn();

  if (loading) {
    return <FullPageLoadingSpinner />;
  }

  return isLoggedIn  ? <Outlet /> : <Navigate to="/auth/signin" replace />;
}

export default PrivateRoute;