// React imports
import React, { useState } from "react";

// External library imports
import { useNavigate } from "react-router-dom";
import { signIn, resendSignUpCode } from "aws-amplify/auth";
import { IconUser, IconLock, IconEyeOff, IconEye } from "@tabler/icons-react";

// MUI component imports
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";

// Local component imports
import AuthTitleText from "../../../components/auth/AuthTitleText/AuthTitleText";
import ActionSection from "../../../components/auth/ActionSection/ActionSection";
import InputGroup from "../../../components/common/InputGroup/InputGroup";
import AuthLink from "../../../components/auth/AuthLink/AuthLink";
import FormAlert from "../../../components/common/FormAlert/FormAlert";

// Utility imports
import {
  usernameIsValid,
  passwordIsValid,
} from "../../../util/validation/inputValidation";

function SignIn() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({
    username: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const validateField = (name, value) => {
    switch (name) {
      case "username":
        return !usernameIsValid(value) ? "Invalid username" : "";
      case "password":
        return !passwordIsValid(value) ? "Invalid password" : "";
      default:
        return "";
    }
  };

  const handleUserNotConfirmed = async (username) => {
    try {
      // resend confirm account code to email
      await resendSignUpCode({ username });

      const params = new URLSearchParams({
        username: username,
      }).toString();

      // use the username to navigate to the confirm account page
      navigate(`/auth/signup/confirmaccount?${params}`);
    } catch (error) {
      console.error(error);
      return "Your account has not been confirmed yet, we tried to email you a confirmation code, but something went wrong. Please try again later.";
    }
  };

  const handleSubmit = async () => {
    // Validate email and password fields
    const usernameError = validateField("username", formData.username);
    const passwordError = validateField("password", formData.password);

    // Set form errors
    setFormErrors({
      username: usernameError,
      password: passwordError,
    });

    // If there are validation errors, return early
    if (usernameError || passwordError) {
      return;
    }

    setLoading(true);
    setSubmitError(null);

    try {
      // login user
      const { nextStep } = await signIn({
        username: formData.username,
        password: formData.password,
      });

      switch (nextStep.signInStep) {
        case "RESET_PASSWORD":
          setSubmitError(
            "A password reset is required for this account. Please reset your password."
          );
          break;

        case "CONFIRM_SIGN_UP":
          const confirmSignupError = await handleUserNotConfirmed(
            formData.username
          );
          setSubmitError(confirmSignupError);
          break;

        case "DONE":
          // Navigate the user to the landing page (or the dashboard after login)
          navigate("/blueprints");
          break;

        default:
          throw new Error(`Unhandled nextStep: ${nextStep}`);
      }

      if (!nextStep) {
        throw new Error("nextStep not found in login result");
      }
    } catch (error) {
      // catch any errors and set them in the error state
      let errorMessage;

      // Catch cognito service errors
      if (error.name) {
        switch (error.name) {
          case "NotAuthorizedException":
            errorMessage = "Incorrect username or password. Please try again.";
            break;
          case "LimitExceededException":
            errorMessage =
              "You've exceeded the maximum number of allowed requests. Please try again later.";
            break;
          case "TooManyRequestsException":
            errorMessage =
              "You've made too many requests. Please wait a moment and try again.";
            break;
          default:
            errorMessage =
              "An unexpected error occurred while attempting to log in.";
        }
      } else {
        errorMessage =
          "An unexpected error occurred while attempting to log in.";
      }

      console.error("Error during account login:", error);
      setSubmitError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div>
      <AuthTitleText
        title="Sign In"
        text="Sign in to your WealthSphere account below."
      />
      <ActionSection>
        <InputGroup>
          <TextField
            id="username"
            name="username"
            label="Username"
            placeholder="username"
            type="text"
            variant="outlined"
            required
            fullWidth
            value={formData.username}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            error={!!formErrors.username}
            helperText={formErrors.username}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <IconUser size={24} />
                  </InputAdornment>
                ),
              },
            }}
          />
          <TextField
            id="password"
            name="password"
            label="Password"
            placeholder="••••••••••"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            required
            fullWidth
            value={formData.password}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            error={!!formErrors.password}
            helperText={formErrors.password}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <IconLock size={24} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      aria-label={
                        showPassword ? "Hide password" : "Show password"
                      }
                      edge="end"
                    >
                      {showPassword ? (
                        <IconEyeOff size={24} />
                      ) : (
                        <IconEye size={24} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
          <p style={{ textAlign: "right" }} className="normal-text-regular">
            <span>
              <AuthLink to="/auth/forgotpassword" text="Forgot Password?" />
            </span>
          </p>
        </InputGroup>
        {submitError && <FormAlert type="error" message={submitError} />}
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit}
          fullWidth
          startIcon={
            loading ? (
              <CircularProgress
                thickness={4}
                size={16}
                sx={{ color: "#FFFFFF" }}
              />
            ) : null
          }
        >
          Sign In
        </Button>
        <p style={{ textAlign: "center" }} className="normal-text-regular">
          Don't have an account?{" "}
          <span>
            <AuthLink to="/auth/signup" text="Sign Up" />
          </span>
        </p>
      </ActionSection>
    </div>
  );
}

export default SignIn;
