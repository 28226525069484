// Core imports
import React, { useState } from "react";

// Routing imports
import { useNavigate } from "react-router-dom";

// Constants
import { LINKS } from "../../../constants/constants";

// Third-party component library imports
import Button from "@mui/material/Button";

// Custom components
import ModalVideo from "../../common/ModalVideo/ModalVideo";

// Assets
import { ReactComponent as PlayVideo } from "../../../assets/images/PlayVideoThumbnail520x368.svg";

// Styles
import styles from "./InfoVideo.module.css";

function InfoVideo() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleOpenModal() {
    setIsModalOpen(true);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  function handleNavigate() {
    navigate("/auth/signup");
  }

  return (
    <>
      <div className={styles.infoVideoContainer}>
        <PlayVideo
          className={styles.playVideoImage}
          onClick={handleOpenModal}
        />
        <div className={styles.contentContainer}>
          <h2>Money Doesn't Have to Be Hard</h2>
          <p className={["normal-text-regular"]}>
            Create a plan that works for you.
          </p>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            
            onClick={handleNavigate}
          >
            Start Your Free Trial
          </Button>
        </div>
      </div>
      <ModalVideo
        videoLink={LINKS.wealthsphereIntroVideo}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      />
    </>
  );
}

export default InfoVideo;
