import { Amplify } from "aws-amplify";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { CookieStorage } from "aws-amplify/utils";

Amplify.configure({
  Auth: {
    Cognito: {
      region: process.env.REACT_APP_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
      authenticationFlowType: "USER_SRP_AUTH",
      mandatorySignIn: false, // Allows public access to the landing page
      advancedSecurityDataCollectionFlag: true, // Enable advanced security features
      passwordPolicy: {
        minLength: 8,
        requireLowercase: true,
        requireUppercase: true,
        requireNumbers: true,
        requireSpecialCharacters: true,
      },
    },
  },
});


const getCookieDomain = () => {
  switch (window.location.hostname) {
    case "localhost":
      return "localhost";
    default:
      return process.env.REACT_APP_DOMAIN
  }
};

const isLocalhost = () => window.location.hostname === "localhost";

const isCookieSecure = () =>
  // if localhost = not secure because https is not in use
  // if dev (staging) then it should be secure and only used over https
  // if prod then it should be secure and only used over https
  process.env.REACT_APP_STAGE === "production" || 
  (process.env.REACT_APP_STAGE === "development" && !isLocalhost());


cognitoUserPoolsTokenProvider.setKeyValueStorage(
  new CookieStorage({
    domain: getCookieDomain(),
    path: "/",
    expires: 3, // based on the refresh token expiry time
    secure:  isCookieSecure(),
    sameSite: "strict",
  })
);