// Core libs and third party libraries
import React from "react";
import { useNavigate } from "react-router-dom"; 
import Button from "@mui/material/Button"; 

// Custom components and SVGs
import { ReactComponent as HeroImage } from "../../../assets/images/HeroImage506x368.svg";

// CSS modules or styles
import styles from "./Hero.module.css";

function Hero() {
  const navigate = useNavigate();

  return (
    <div className={styles.heroContainer}>
      <div className={styles.contentContainer}>
        <h1>Simplify Your Finances</h1>
        <p className={["normal-text-regular"]}>
          WealthSphere helps you create simple long-term financial blueprints
          that offer a clear, holistic view of your finances, helping you map
          out your financial journey year by year.
        </p>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => navigate("/auth/signup")}
        >
          Start Your Free Trial
        </Button>
      </div>
      <HeroImage className={styles.heroImage} />
    </div>
  );
}

export default Hero;
