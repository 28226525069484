import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button"; 
import styles from "./CTA.module.css";

function CTA() {
  const navigate = useNavigate();

  return (
    <div className={styles.ctaContainer}>
      <div className={styles.ctaCard}>
        <h2>See the Bigger Picture</h2>
        <p className={["large-text-regular"]}>
          Start planning with WealthSphere and take control of your money.
        </p>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => navigate("/auth/signup")}
        >
          Start Your Free Trial
        </Button>
      </div>
    </div>
  );
}

export default CTA;
