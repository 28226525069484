// React imports
import React, { useState } from "react";

// External library imports
import { useNavigate } from "react-router-dom";
import { resetPassword } from "aws-amplify/auth";
import { IconUser } from "@tabler/icons-react";

// MUI component imports
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

// Local component imports
import AuthTitleText from "../../../components/auth/AuthTitleText/AuthTitleText";
import ActionSection from "../../../components/auth/ActionSection/ActionSection";
import InputGroup from "../../../components/common/InputGroup/InputGroup";
import AuthLink from "../../../components/auth/AuthLink/AuthLink";
import FormAlert from "../../../components/common/FormAlert/FormAlert";

// Utility imports
import { usernameIsValid } from "../../../util/validation/inputValidation";

function ForgotPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [formData, setFormData] = useState({
    username: "",
  });
  const [formErrors, setFormErrors] = useState({
    username: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const validateField = (name, value) => {
    switch (name) {
      case "username":
        return !usernameIsValid(value) ? "Invalid username" : "";
      default:
        return "";
    }
  };

  const handleSubmit = async () => {
    // Validate username field
    const usernameError = validateField("username", formData.username);

    // Set form errors
    setFormErrors({
      username: usernameError,
    });

    // If there are validation errors, return early
    if (usernameError) {
      return;
    }

    setLoading(true);
    setSubmitError(null);

    try {
      // Send forgot password code which is used on the reset password page
      await resetPassword({ username: formData.username });

      // Navigate to the reset password page
      const params = new URLSearchParams({
        username: formData.username,
      }).toString();

      navigate(`/auth/forgotpassword/resetpassword?${params}`);
    } catch (error) {
      let errorMessage;

      // Catch cognito service errors
      if (error.name) {
        switch (error.name) {
          case "InvalidParameterException":
            errorMessage =
              "The username provided is invalid. Please check and try again.";
            break;
          case "CodeDeliveryFailureException":
            errorMessage =
              "There was a problem sending the verification code. Please try again.";
            break;
          case "LimitExceededException":
            errorMessage =
              "You've exceeded the maximum number of allowed requests. Please try again later.";
            break;
          case "TooManyRequestsException":
            errorMessage =
              "You've made too many requests. Please wait a moment and try again.";
            break;
          default:
            errorMessage = "An unexpected error occurred.";
        }
      } else {
        errorMessage = "An unexpected error occurred.";
      }

      console.error("Error during account password reset:", error);
      setSubmitError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <AuthTitleText
        title="Forgot Password"
        text="Enter the username associated with your WealthSphere account, and we'll send you a code to reset your account password."
      />
      <ActionSection>
        <InputGroup>
          <TextField
            id="username"
            name="username"
            label="Username"
            placeholder="username"
            type="text"
            variant="outlined"
            required
            fullWidth
            value={formData.username}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            error={!!formErrors.username}
            helperText={formErrors.username}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <IconUser size={24} />
                  </InputAdornment>
                ),
              },
            }}
          />
        </InputGroup>
        {submitError && <FormAlert type="error" message={submitError} />}
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit}
          fullWidth
          startIcon={
            loading ? (
              <CircularProgress
                thickness={4}
                size={16}
                sx={{ color: "#FFFFFF" }}
              />
            ) : null
          }
        >
          Send Email
        </Button>
        <p style={{ textAlign: "center" }} className="normal-text-regular">
          Don't have an account?{" "}
          <span>
            <AuthLink to="/auth/signup" text="Sign Up" />
          </span>
        </p>
      </ActionSection>
    </div>
  );
}

export default ForgotPassword;
