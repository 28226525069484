import { createTheme } from "@mui/material/styles";
import { COLORS, TYPOGRAPHY } from "./constants/constants";

const muiTheme = createTheme({
  palette: {
    primary: {
      main: COLORS.primary300,
      contrastText: COLORS.accent300,
    },
    secondary: {
      main: COLORS.secondary200,
      contrastText: COLORS.accent300,
    },
    error: {
      main: COLORS.error300,
      contrastText: COLORS.accent300,
    },
    warning: {
      main: COLORS.warning300,
      contrastText: COLORS.accent300,
    },
    success: {
      main: COLORS.success300,
      contrastText: COLORS.accent300,
    },
  },
  typography: {
    fontFamily: "DM Sans, sans-serif", // Default font for all text

    // Headings
    h1: {
      fontSize: TYPOGRAPHY.h1.fontSize,
      lineHeight: TYPOGRAPHY.h1.lineHeight,
      fontWeight: TYPOGRAPHY.h1.fontWeight,
      fontFamily: TYPOGRAPHY.h1.fontFamily,
    },
    h2: {
      fontSize: TYPOGRAPHY.h2.fontSize,
      lineHeight: TYPOGRAPHY.h2.lineHeight,
      fontWeight: TYPOGRAPHY.h2.fontWeight,
      fontFamily: TYPOGRAPHY.h2.fontFamily,
    },
    h3: {
      fontSize: TYPOGRAPHY.h3.fontSize,
      lineHeight: TYPOGRAPHY.h3.lineHeight,
      fontWeight: TYPOGRAPHY.h3.fontWeight,
      fontFamily: TYPOGRAPHY.h3.fontFamily,
    },
    h4: {
      fontSize: TYPOGRAPHY.h4.fontSize,
      lineHeight: TYPOGRAPHY.h4.lineHeight,
      fontWeight: TYPOGRAPHY.h4.fontWeight,
      fontFamily: TYPOGRAPHY.h4.fontFamily,
    },
    h5: {
      fontSize: TYPOGRAPHY.h5.fontSize,
      lineHeight: TYPOGRAPHY.h5.lineHeight,
      fontWeight: TYPOGRAPHY.h5.fontWeight,
      fontFamily: TYPOGRAPHY.h5.fontFamily,
    },
    h6: {
      fontSize: TYPOGRAPHY.h6.fontSize,
      lineHeight: TYPOGRAPHY.h6.lineHeight,
      fontWeight: TYPOGRAPHY.h6.fontWeight,
      fontFamily: TYPOGRAPHY.h6.fontFamily,
    },

    // Body text styles
    body1: {
      fontSize: TYPOGRAPHY.normalTextRegular.fontSize,
      lineHeight: TYPOGRAPHY.normalTextRegular.lineHeight,
      fontWeight: TYPOGRAPHY.normalTextRegular.fontWeight,
      fontFamily: TYPOGRAPHY.normalTextRegular.fontFamily,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none", // Prevents button text from being uppercase
          borderRadius: "24px",
          fontSize: TYPOGRAPHY.normalTextBold.fontSize,
          lineHeight: TYPOGRAPHY.normalTextBold.lineHeight,
          fontWeight: TYPOGRAPHY.normalTextBold.fontWeight,
          fontFamily: TYPOGRAPHY.normalTextBold.fontFamily,
        },
        sizeSmall: {
          padding: "8px 16px", 
        },
        sizeMedium: {
          padding: "8px 24px",
        },
        sizeLarge: {
          padding: "16px 32px", 
        },
        outlined: {
          borderWidth: "4px",
        },
        outlinedSizeSmall: {
          padding: "4px 12px",
        },
        outlinedSizeMedium: {
          padding: "4px 20px",
        },
        outlinedSizeLarge: {
          padding: "12px 28px",
        },
      },
    },
  },
});

export default muiTheme;
