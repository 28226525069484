// React imports
import React, { useState, useEffect } from "react";

// External library imports
import { useNavigate, useSearchParams } from "react-router-dom";
import { confirmResetPassword } from "aws-amplify/auth";
import { IconLock, IconEyeOff, IconEye } from "@tabler/icons-react";

// MUI component imports
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";

// Local component imports
import AuthTitleText from "../../../components/auth/AuthTitleText/AuthTitleText";
import ActionSection from "../../../components/auth/ActionSection/ActionSection";
import InputGroup from "../../../components/common/InputGroup/InputGroup";
import FormAlert from "../../../components/common/FormAlert/FormAlert";

// Utility imports
import {
  passwordIsValid,
  confirmationCodeIsValid,
} from "../../../util/validation/inputValidation";

function ResetPassword() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const username = searchParams.get("username");
  const [passwordReset, setPasswordReset] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [formData, setFormData] = useState({
    confirmationCode: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({
    confirmationCode: "",
    password: "",
  });

  useEffect(() => {
    if (!username) {
      console.log(
        "No username found. Navigating to the forgot password page."
      );
      navigate("/auth/forgotpassword");
    }
  }, [username, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const validateField = (name, value) => {
    switch (name) {
      case "confirmationCode":
        return !confirmationCodeIsValid(value)
          ? "Please enter the 6-digit verification code we sent you"
          : "";
      case "password":
        return !passwordIsValid(value) ? "Invalid password" : "";
      default:
        return "";
    }
  };

  const handleUserFinished = () => {
    navigate("/auth/signin");
  };

  const handleSubmit = async () => {
    // Validate confirmation code and password fields
    const confirmationCodeError = validateField(
      "confirmationCode",
      formData.confirmationCode
    );
    const passwordError = validateField("password", formData.password);

    // Set form errors
    setFormErrors({
      confirmationCode: confirmationCodeError,
      password: passwordError,
    });

    // If there are validation errors, return early
    if (confirmationCodeError || passwordError) {
      return;
    }

    setLoading(true);
    setSubmitError(null);

    try {
      // Confirm the reset password
      await confirmResetPassword({
        confirmationCode: formData.confirmationCode,
        newPassword: formData.password,
        username: username
      })
     
      setPasswordReset(true)
    } catch (error) {
      let errorMessage;
 
      // Catch cognito service errors
      if (error.name) {
        switch (error.name) {
          case "CodeMismatchException":
            errorMessage =
              "The verification code you entered is incorrect. Please try again.";
            break;
          case "ExpiredCodeException":
            errorMessage =
              "The verification code has expired. Please request a new one.";
            break;
          case "InvalidPasswordException":
            errorMessage =
              "The password you entered does not meet the password requirements. Please try a different one.";
            break;
          case "PasswordHistoryPolicyViolationException":
            errorMessage =
              "You cannot reuse a previous password. Please choose a new one.";
            break;
          case "UserNotConfirmedException":
            errorMessage =
              "Your account has not been confirmed. Please login with your current password to confirm your account first.";
            break;
          case "LimitExceededException":
            errorMessage =
              "You've exceeded the maximum number of allowed requests. Please try again later.";
            break;
          case "TooManyRequestsException":
            errorMessage =
              "You've made too many requests. Please wait a moment and try again.";
            break;
          default:
            errorMessage = "An unexpected error occurred.";
        }
      } else {
        errorMessage = "An unexpected error occurred.";
      }

      console.error("Error during account password reset confirmation:", error);
      setSubmitError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const passwordResetContent = (
    <div>
      <AuthTitleText
        title="Reset Password"
        text="Reset your WealthSphere account password below."
      />
      <ActionSection>
        <InputGroup>
          <TextField
            id="confirmationCode"
            name="confirmationCode"
            label="Verification Code"
            placeholder="123456"
            type="text"
            variant="outlined"
            required
            fullWidth
            value={formData.confirmationCode}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            error={!!formErrors.confirmationCode}
            helperText={formErrors.confirmationCode}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <IconLock size={24} />
                  </InputAdornment>
                ),
              },
            }}
          />
           <TextField
            id="password"
            name="password"
            label="New Password"
            placeholder="••••••••••"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            required
            fullWidth
            value={formData.password}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            error={!!formErrors.password}
            helperText={formErrors.password}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <IconLock size={24} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      aria-label={
                        showPassword ? "Hide password" : "Show password"
                      }
                      edge="end"
                    >
                      {showPassword ? (
                        <IconEyeOff size={24} />
                      ) : (
                        <IconEye size={24} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
        </InputGroup>
        {submitError && <FormAlert type="error" message={submitError} />}
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit}
          fullWidth
          startIcon={
            loading ? (
              <CircularProgress
                thickness={4}
                size={16}
                sx={{ color: "#FFFFFF" }}
              />
            ) : null
          }
        >
          Reset Password
        </Button>
      </ActionSection>
    </div>
  );

  const passwordWasResetContent = (
    <div>
      <AuthTitleText
        title="Password Reset Successful"
        text="Your WealthSphere account password has been successfully reset. You can now log in with your new password."
      />
      <ActionSection>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleUserFinished}
          fullWidth
        >
          Login
        </Button>
      </ActionSection>
    </div>
  );

  return passwordReset ? passwordWasResetContent : passwordResetContent;
}

export default ResetPassword;
