import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signUp } from "aws-amplify/auth";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import AuthTitleText from "../../../components/auth/AuthTitleText/AuthTitleText";
import ActionSection from "../../../components/auth/ActionSection/ActionSection";
import InputGroup from "../../../components/common/InputGroup/InputGroup";
import AuthLink from "../../../components/auth/AuthLink/AuthLink";
import FormAlert from "../../../components/common/FormAlert/FormAlert";

import {
  emailIsValid,
  passwordIsValid,
  usernameIsValid,
} from "../../../util/validation/inputValidation";

import {
  IconUser,
  IconMail,
  IconLock,
  IconEyeOff,
  IconEye,
} from "@tabler/icons-react";

function SignUp() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    country: "",
  });
  const [formErrors, setFormErrors] = useState({
    username: "",
    email: "",
    password: "",
    country: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setFormErrors((prev) => ({ ...prev, [name]: error }));
  };

  const validateField = (name, value) => {
    switch (name) {
      case "username":
        return !usernameIsValid(value)
          ? "Username must be 1-48 characters long, using only lowercase letters and numbers."
          : "";
      case "email":
        return !emailIsValid(value) ? "Invalid email address" : "";
      case "password":
        return !passwordIsValid(value)
          ? "Your password must be at least 8 characters long, contain at least one number, one uppercase letter, one lowercase letter, and one special character."
          : ""; // should be pass validation
      case "country":
        return value ? "" : "Please select a country.";
      default:
        return "";
    }
  };

  // Handle subscribe button click
  const handleSubmit = async () => {
    const usernameError = validateField("username", formData.username);
    const emailError = validateField("email", formData.email);
    const passwordError = validateField("password", formData.password);
    const countryError = validateField("country", formData.country);
    // validate country

    setFormErrors({
      username: usernameError,
      email: emailError,
      password: passwordError,
      country: countryError,
    });


    if (usernameError || emailError || passwordError || countryError) {
      return;
    }

    if (formData.country !== "usa") {
      setSubmitError("Thank you for your interest. However, we are only accepting sign-ups from customers based in the United States at this time.")
      return
    }


    setLoading(true);
    setSubmitError(null);
    try {
      await signUp({
        username: formData.username,
        password: formData.password,
        options: {
          userAttributes: {
            email: formData.email,
            // Additional attributes can be added here if needed
          },
        },
        //autoSignIn: { enabled: true },
      });

      const params = new URLSearchParams({
        username: formData.username,
      }).toString();

      navigate(`/auth/signup/confirmaccount?${params}`);
    } catch (error) {
      let errorMessage;

      // Catch cognito service errors
      if (error.name) {
        switch (error.name) {
          case "CodeDeliveryFailureException":
            errorMessage =
              "There was a problem sending the verification code. Please try again.";
            break;
          case "LimitExceededException":
            errorMessage =
              "You've exceeded the maximum number of allowed requests. Please try again later.";
            break;
          case "TooManyRequestsException":
            errorMessage =
              "You've made too many requests. Please wait a moment and try again.";
            break;
          default:
            errorMessage = "An unexpected error occurred.";
        }
      } else {
        errorMessage = "An unexpected error occurred.";
      }

      console.error("Error during account sign up:", error);
      setSubmitError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div>
      <AuthTitleText
        title="Sign Up"
        text="Create your WealthSphere account below."
      />
      <ActionSection>
        <InputGroup>
          <TextField
            id="username"
            name="username"
            label="Username"
            placeholder="username"
            type="text"
            variant="outlined"
            required
            fullWidth
            value={formData.username}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            error={!!formErrors.username}
            helperText={formErrors.username}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <IconUser size={24} />
                  </InputAdornment>
                ),
              },
            }}
          />
          <TextField
            id="email"
            name="email"
            label="Email"
            placeholder="example@domain.com"
            type="email"
            variant="outlined"
            required
            fullWidth
            value={formData.email}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            error={!!formErrors.email}
            helperText={formErrors.email}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <IconMail size={24} />
                  </InputAdornment>
                ),
              },
            }}
          />

          <FormControl fullWidth>
            <InputLabel id="country">Country</InputLabel>
            <Select
              labelId="country"
              id="country"
              name="country"
              value={formData.country}
              onChange={handleInputChange}
              error={!!formErrors.country}
              onBlur={handleInputBlur}
              label="Country"
            >
              <MenuItem value="">
                <em>Select a country</em> {/* Acts as a placeholder */}
              </MenuItem>
              <MenuItem value="usa">United States</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
            {formErrors.country && (
              <FormHelperText error>{formErrors.country}</FormHelperText>
            )}
          </FormControl>

          <TextField
            id="password"
            name="password"
            label="Password"
            placeholder="••••••••••"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            required
            fullWidth
            value={formData.password}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            error={!!formErrors.password}
            helperText={formErrors.password}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <IconLock size={24} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      aria-label={
                        showPassword ? "Hide password" : "Show password"
                      }
                      edge="end"
                    >
                      {showPassword ? (
                        <IconEyeOff size={24} />
                      ) : (
                        <IconEye size={24} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
        </InputGroup>
        <p style={{ textAlign: "center" }} className="normal-text-regular">
          By signing up, you agree to our{" "}
          <span>
            <AuthLink to="#" text="Terms" />
          </span>{" "}
          and{" "}
          <span>
            <AuthLink to="#" text="Privacy Policy" />
          </span>
          .
        </p>
        {submitError && <FormAlert type="error" message={submitError} />}
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit}
          fullWidth
          startIcon={loading ? <CircularProgress thickness={4} size={16} sx={{ color: '#FFFFFF'}}/> : null}
        >
          Sign Up
        </Button>
        <p style={{ textAlign: "center" }} className="normal-text-regular">
          Already have an account?{" "}
          <span>
            <AuthLink to="/auth/signin" text="Sign In" />
          </span>
        </p>
      </ActionSection>
    </div>
  );
}

export default SignUp;
