// Core imports
import React from "react";
// Routing imports
import { useNavigate } from "react-router-dom";
// Third-party libraries
import Button from "@mui/material/Button"; 
import {
  IconDeviceLaptop,
  IconMoneybag,
  IconChartDonut4,
  IconHeartDollar,
} from "@tabler/icons-react";
// Constants
import { COLORS } from "../../../constants/constants";
// Custom components
import StepCard from "./StepCard/StepCard";
// Styles
import styles from "./HowItWorks.module.css";

function HowItWorks() {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h2>How It Works</h2>
        <div className={styles.buttonContainer}>

          <Button
          variant="contained"
          color="secondary"
          size="large"
          sx={{ whiteSpace: 'nowrap', minWidth: 'max-content' }}
          onClick={() => navigate("/auth/signup")}
        >
          Start Your Free Trial
        </Button>
        </div>
      </div>
      <div className={styles.stepCardContainer}>
        <StepCard
          icon={<IconDeviceLaptop size={32} color={COLORS.primary200} />}
          title="Sign up for free"
          text="Set up your WealthSphere account in just a few clicks and enjoy a 14-day free trial."
        />
        <StepCard
          icon={<IconMoneybag size={32} color={COLORS.primary200} />}
          title="Create your first yearly blueprint"
          text="Build a plan that captures all your expected income and expenses for the year using WealthSphere's intuitive interface."
        />
        <StepCard
          icon={<IconChartDonut4 size={32} color={COLORS.primary200} />}
          title="Review analytics"
          text="Gain valuable insights into your financial health with easy-to-understand analytics and reports."
        />
        <StepCard
          icon={<IconHeartDollar size={32} color={COLORS.primary200} />}
          title="Stay in control"
          text="Take charge of your finances, confidently plan for your long-term goals, and stay on track as you build the future you want."
        />
      </div>
    </div>
  );
}

export default HowItWorks;
