import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import useIsLoggedIn from '../../hooks/useIsLoggedIn/useIsLoggedIn';
import FullPageLoadingSpinner from '../../components/common/FullPageLoadingSpinner/FullPageLoadingSpinner';
import SignUp from '../../pages/auth/SignUp/SignUp';
import SignIn from '../../pages/auth/SignIn/SignIn';
import ConfirmAccount from '../../pages/auth/ConfirmAccount/ConfirmAccount';
import ForgotPassword from '../../pages/auth/ForgotPassword/ForgotPassword';
import ResetPassword from '../../pages/auth/ResetPassword/ResetPassword';


function AuthRoute() {
  const { isLoggedIn, loading } = useIsLoggedIn();

  if (loading) {
    return <FullPageLoadingSpinner />;
  }

  if (isLoggedIn) {
    return <Navigate to="/blueprints" replace />;
  }

  return (
    <Routes>
      <Route path="signup" element={<SignUp />} />
      <Route path="signup/confirmaccount" element={<ConfirmAccount />} />
      <Route path="signin" element={<SignIn />} />
      <Route path="forgotpassword" element={<ForgotPassword />} />
      <Route path="forgotpassword/resetpassword" element={<ResetPassword />} /> 
      <Route path="*" element={<Navigate to="/404" replace />} /> 
    </Routes>

    // TEMP WHILE DEV IN PROGRESS
    // <Routes>
    //   <Route path="signup" element={<Navigate to="/" replace />} />
    //   <Route path="signup/confirmaccount" element={<Navigate to="/" replace />} />
    //   <Route path="signin" element={<Navigate to="/" replace />} />
    //   <Route path="forgotpassword" element={<Navigate to="/" replace />} />
    //   <Route path="forgotpassword/resetpassword" element={<Navigate to="/" replace />} />
    //   <Route path="*" element={<Navigate to="/404" replace />} /> 
    // </Routes>
  );
}

export default AuthRoute;