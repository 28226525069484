import React from 'react';
import PropTypes from 'prop-types';
import mergeClassNames from '../../../util/mergeClassNames/mergeClassNames';
import styles from './AuthTitleText.module.css';

function AuthTitleText({ title, text = null }) {
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{title}</h2>
      {text && <p className={mergeClassNames('normal-text-regular', styles.text)}>{text}</p>}
    </div>
  );
}

AuthTitleText.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default AuthTitleText;