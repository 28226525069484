// External library imports
import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

// Asset imports
import { ReactComponent as NotFoundImg } from "../../../assets/images/NotFound.svg";

// Internal shared or common component imports
import LayoutSection from "../../../components/common/LayoutSection/LayoutSection";
import ImageWithWrappingContent from "../../../components/common/ImageWithWrappingContent/ImageWithWrappingContent";

// Local style imports
import styles from "./NotFound.module.css";

function NotFound() {
  const navigate = useNavigate();

  const handleNavigateHome = () => {
    navigate("/");
  };

  return (
    <>
      <LayoutSection customClasses={[styles.section]}>
        <ImageWithWrappingContent
          title="Uh Oh, Page Not Found!"
          subText="The page you're looking for doesn't exist. You may have mistyped the address or the page may have moved."
          imageComponent={<NotFoundImg />}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleNavigateHome}
            fullWidth
          >
            Return To Home
          </Button>
        </ImageWithWrappingContent>
      </LayoutSection>
    </>
  );
}

export default NotFound;
