import React from 'react';
import CustomLink from '../../common/CustomLink/CustomLink';
import styles from './AuthLink.module.css';

function AuthLink({text, to, onClick}) {
  return (
    <CustomLink className={styles.link} to={to} onClick={onClick}>{text}</CustomLink>
  );
}

export default AuthLink;