// Core imports
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import * as Sentry from "@sentry/react"; // Initialize Sentry

// MUI imports
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import muiTheme from "./muiTheme"; // Import your basic MUI theme

// Configuration imports
import "./config/amplify-config"; // Initialize Amplify

// Custom components and layouts
import ScrollToTop from "./components/common/ScrollToTop/ScrollToTop";
import LandingPagesLayout from "./layouts/LandingPagesLayout/LandingPagesLayout";
import AuthLayout from "./layouts/AuthLayout/AuthLayout";

// Route imports
import AuthRoute from "./routes/AuthRoute/AuthRoute";
import RootRoute from "./routes/RootRoute/RootRoute";
import PrivateRoute from "./routes/PrivateRoute/PrivateRoute";
import BlueprintsRoute from "./routes/BlueprintsRoute/BlueprintsRoute";

function App() {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <Router>
        <ScrollToTop />
        <SentryRoutes>
          {/* Landing Pages */}
          <Route element={<LandingPagesLayout />}>
            <Route path="/*" element={<RootRoute />} />
          </Route>
          {/* Auth Pages */}
          <Route element={<AuthLayout />}>
            <Route path="/auth/*" element={<AuthRoute />} />
          </Route>
          {/* Private Routes */}
          <Route element={<PrivateRoute />}>
            <Route path="/blueprints/*" element={<BlueprintsRoute />} />
          </Route>
          {/* ---------- */}
        </SentryRoutes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
